
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, watch, computed } from "vue"
import { Edit } from "@element-plus/icons-vue"

import { useRoute, useRouter } from "vue-router"
import { SelectModel } from "@/core/models/SelectModel"
import { InvoiceType } from "@/core/models/Invoice"
import { PaymentScheduleSummary } from "@/core/models/PaymentScheduleSummary"
import { ElForm } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { InternalRuleItem, Value } from "async-validator"
import { PaymentScheduleFilter } from "@/core/models/PaymentScheduleFilter"
import { DateFormatTypes, dateForm, today, lastDayofMonth } from "@/core/helpers/dateformat"
import { Payment } from "@/core/models/Payment"
import { PaymentItem } from "@/core/models/PaymentItem"
import { TableColumnCtx } from "element-plus/es/components/table/src/table-column/defaults"
import ElInputCurrency from "@/components/input/ElInputCurrency.vue"
import { currencyForm } from "@/core/helpers/currencyformat"
import { CashBookListItem } from "@/core/models/CashBookListItem"

export default defineComponent({
  name: "payment",
  components: {
    ElInputCurrency,
  },
  setup() {
    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()
    const payRuleFormRef = ref<FormInstance>()

    const router = useRouter()
    const route = useRoute()

    const invoice = ref<PaymentScheduleSummary>({} as PaymentScheduleSummary)
    const paymentData = ref<Payment>({} as Payment)
    const formData = ref<PaymentScheduleFilter>({
      startDate: today(),
      endDate: lastDayofMonth(),
    } as PaymentScheduleFilter)

    const isTableDataLoading = ref(false)
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isCashBookSelectLoading = ref(false)
    const isDialogVisible = ref(false)
    const isPaymentLoading = ref(false)
    const isPaymentListLoading = ref(false)

    const size = ref("small")

    const invoiceList = ref([] as PaymentScheduleSummary[])
    const branchList = ref<SelectModel[]>([])
    const businessList = ref<SelectModel[]>([])
    const cashBookList = ref<CashBookListItem[]>([])
    const paymentList = ref<PaymentItem[]>([])

    const totalCount = ref<number>(0)
    const pageCount = ref<number>(0)
    const pageSize = ref<number>(10)
    const currentPage = ref<number>(1)

    const validateStartDate = (
      rule: InternalRuleItem,
      value: Value,
      callback: (error?: string | Error) => void
    ) => {
      let end = formData.value.endDate as Date
      if (value && end && value > end)
        callback(new Error("Başlangıç zamanı bitiş zamanından önce olmalıdır"))
      else callback()
    }

    const validateEndDate = (
      rule: InternalRuleItem,
      value: Value,
      callback: (error?: string | Error) => void
    ) => {
      let start = formData.value.startDate as Date
      if (value && start && value <= start)
        callback(new Error("Bitiş zamanı başlangıç zamanından sonra olmalıdır"))
      else callback()
    }

    const rules = ref({
      businessId: getRule(RuleTypes.SELECT, "İşletme"),
      startDate: getRule(RuleTypes.DATE, "Başlangıç", [
        {
          validator: validateStartDate,
          trigger: "change",
        },
      ]),
      endDate: getRule(RuleTypes.DATE, "Bitiş", [
        {
          validator: validateEndDate,
          trigger: "change",
        },
      ]),
    })

    interface SummaryMethodProps<T> {
      columns: TableColumnCtx<T>[]
      data: T[]
    }

    function getSummaries<T>(param: SummaryMethodProps<T>) {
      const { columns, data } = param
      const sums: string[] = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Toplam Ödeme"
          return
        }
        if (column.property !== "amount") {
          sums[index] = ""
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (values.every(value => Number.isNaN(value))) {
          sums[index] = ""
          return
        }
        const itemValue = values.reduce((prev, curr) => {
          const value = Number(curr)
          if (!Number.isNaN(value)) {
            return prev + curr
          } else {
            return prev
          }
        }, 0)
        if (itemValue === 0) {
          sums[index] = ""
        } else {
          sums[index] = `${currencyForm(parseFloat(itemValue.toFixed(2)))}`
        }
      })
      return sums
    }

    const payRules = ref({
      cashbookId: getRule(RuleTypes.SELECT, "Hesap"),
      paymentAmount: getRule(RuleTypes.NUMERIC, "Tutar"),
    })

    const onBusinessChange = () => {
      formData.value.branchId = undefined
      branchList.value = []
      getBranchList(formData.value.businessId)
      getInvoiceList(ruleFormRef.value)
    }

    const getBusinessList = async () => {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length == 1) {
        formData.value.businessId = businessList.value[0].id
        onBusinessChange()
      }
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      isBranchSelectLoading.value = false
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
    }

    const handleSizeChange = async (val: number) => {
      const isFormValid = await ruleFormRef.value?.validate(x => x)
      if (isFormValid) {
        pageSize.value = val
        await getInvoiceList(ruleFormRef.value)
      }
    }

    const handleCurrentChange = async (val: number) => {
      const isFormValid = await ruleFormRef.value?.validate(x => x)
      if (isFormValid) {
        currentPage.value = val
        await getInvoiceList(ruleFormRef.value)
      }
    }

    watch(
      () => route.params.invoice_id,
      async newValue => {
        invoice.value = {} as PaymentScheduleSummary
        if (newValue) {
          await getInvoiceDetail(newValue)
          isDialogVisible.value = true
        }
      }
    )

    const getInvoiceDetail = async invoiceId => {
      const { data } = await ApiService.get("payment-schedule/" + invoiceId)
      invoice.value = data
      paymentData.value.invoiceId = invoiceId
      data.businessId
        ? await getBusinessCashBookList(data.businessId)
        : await getBranchCashBookList(data.branchId)

      await getPaymentList(invoiceId)
    }

    const computedDescription = computed(() => {
      const marginMap = {
        large: "32px",
        default: "28px",
        small: "24px",
      }
      return {
        marginBottom: marginMap[size.value] || marginMap.default,
      }
    })

    const getInvoiceList = async formEl => {
      if (!formEl) return
      await formEl.validate(async valid => {
        if (valid) {
          isTableDataLoading.value = true
          let query = {
            params: {
              invoiceType: InvoiceType.PurchaseInvoice,
              businessId: formData.value.businessId,
              branchId: formData.value.branchId,
              isOnlyPending: formData.value.isOnlyPending,
              startDate: formData.value.startDate,
              endDate: formData.value.endDate,

              _pageSize: pageSize.value,
              _pageNumber: currentPage.value,
            },
          }

          const { data } = await ApiService.query("payment-schedule", query)
          invoiceList.value = data._data
          isTableDataLoading.value = false
          totalCount.value = data._totalData
          pageCount.value = data._pageCount
        }
      })
    }

    const getBranchCashBookList = async branchId => {
      isCashBookSelectLoading.value = true
      const { data } = await ApiService.get("cash-book/branch/" + branchId)
      isCashBookSelectLoading.value = false
      //TODO: Diğer ödeme tipleri eklenecek
      cashBookList.value = data
        .filter(x => x.bankAccountType == null)
        .map(x => ({
          id: x.id,
          name: x.name,
          balance: x.balance,
        }))
    }

    const getBusinessCashBookList = async businessId => {
      isCashBookSelectLoading.value = true
      const { data } = await ApiService.get("cash-book/business/" + businessId)
      isCashBookSelectLoading.value = false
      //TODO: Diğer ödeme tipleri eklenecek
      cashBookList.value = data
        .filter(x => x.bankAccountType == null)
        .map(x => ({
          id: x.id,
          name: x.name,
          balance: x.balance,
        }))
    }

    const makePayment = async formEl => {
      await formEl.validate(async valid => {
        if (valid) {
          isPaymentLoading.value = true
          //TODO: paymentMethod pos / cheque eklenecek
          paymentData.value.paymentMethod = 1
          try {
            await ApiService.post("payment/add", paymentData.value)
          } finally {
            formEl.resetFields()
            isPaymentLoading.value = false
            await getInvoiceDetail(paymentData.value.invoiceId)
          }
        }
      })
    }

    const getPaymentList = async invoiceId => {
      isPaymentListLoading.value = true
      const { data } = await ApiService.get("invoice/" + invoiceId + "/payments")
      paymentList.value = data
      isPaymentListLoading.value = false
    }

    function closeDialog() {
      isDialogVisible.value = false
      paymentData.value = {} as Payment
    }

    onMounted(async () => {
      getBusinessList()
      isDialogVisible.value = !!route.params.invoice_id
      if (route.params.invoice_id) await getInvoiceDetail(route.params.invoice_id)
    })

    const onClose = async () => {
      router.push({ name: "payment" })

      const isFormValid = await ruleFormRef.value?.validate(x => x)
      if (isFormValid) {
        await getInvoiceList(ruleFormRef.value)
      }
    }

    const invoiceStatus = _invoice => {
      if (_invoice)
        if (_invoice.totalPaidAmount >= _invoice.totalAmount) return "Ödendi"
        else if (_invoice.totalPaidAmount > 0) return "Kısmi Ödendi"
        else return "Ödeme Başlamadı"
      else return ""
    }

    return {
      Edit,
      dateForm,
      DateFormatTypes,

      rules,
      payRules,
      ruleFormRef,
      payRuleFormRef,

      pageCount,
      totalCount,
      currentPage,
      pageSize,

      formData,
      invoice,
      paymentData,
      invoiceStatus,

      isTableDataLoading,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      isCashBookSelectLoading,
      isDialogVisible,
      isPaymentLoading,
      isPaymentListLoading,

      makePayment,
      handleSizeChange,
      handleCurrentChange,
      onClose,
      closeDialog,
      onBusinessChange,
      getBusinessList,
      getInvoiceList,
      getInvoiceDetail,
      getPaymentList,
      getSummaries,
      currencyForm,

      branchList,
      businessList,
      cashBookList,
      invoiceList,
      paymentList,

      computedDescription,
      size,
    }
  },
})
